import { DisposalApproval, Disposal, Imc, Analytics, AnalyticsAdmin, SearchApp } from '../icons';

// These need to be lowercase
export const appLogos = {
  disposalapproval: DisposalApproval,
  disposal: Disposal,
  imc: Imc,
  analytics: Analytics,
  analyticsadmin: AnalyticsAdmin,
  search: SearchApp
};

// gets the logo for the specified app
export const getLogoForApp = (appName) => {
  if (appName == null) {
    return null;
  }
  let formattedAppName = appName.replace(/\s/g, '').toLowerCase();
  let logo = appLogos[formattedAppName];
  return logo;
};
