import { SVGProps } from 'react';

const SearchApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={112}
    height={128}
    viewBox='0 0 112 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d="M49 93C68.8823 93 85 76.8823 85 57C85 37.1177 68.8823 21 49 21C29.1177 21 13 37.1177 13 57C13 76.8823 29.1177 93 49 93Z" fill="#FAFAFC" stroke="url(#paint0_linear_434_68846)"/>
    <path d="M49 92C68.33 92 84 76.33 84 57C84 37.67 68.33 22 49 22C29.67 22 14 37.67 14 57C14 76.33 29.67 92 49 92Z" fill="white"/>
    <path d="M81.086 88.296L80.486 88.896L75.3 83.712L75.9 83.112L81.086 88.296Z" fill="#FAFAFC" stroke="#4FBEEA"/>
    <path d="M100.236 99.586C101.364 100.721 101.998 102.256 101.998 103.856C101.998 105.456 101.364 106.991 100.236 108.126C99.1012 109.255 97.5656 109.889 95.965 109.889C94.3643 109.889 92.8288 109.255 91.694 108.126L76.554 93.098L80.88 88.772L85.206 84.554L100.236 99.586Z" fill="#DCE1EF"/>
    <defs>
        <linearGradient id="paint0_linear_434_68846" x1="16.796" y1="-9.888" x2="133.978" y2="61.748" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5600D7"/>
            <stop offset="1" stop-color="#00EFEA" stop-opacity="0.66"/>
        </linearGradient>
    </defs>
  </svg>
);

export default SearchApp;