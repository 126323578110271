import { ReactNode } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from "@syncfusion/ej2-react-grids";

export type SortValue = {
  field: string;
  direction: SortDirection;
};

export type ItemLevel = {
  name: string;
  id: string;
  itemId: string;
  isItemCollection?: boolean;
  pageIndex?: number;
  pageSize?: number;
  rowIndex?: number;
  sortSettings?: SortValue;
  prevPageIndex?: number;
  prevPageSize?: number;
  prevRowIndex?: number;
  prevSortSettings?: SortValue;
  icon?: ReactNode;
};

type itemModalStateType = {
  navigation: {
    levels: ItemLevel[];
  };
};

export const itemInitialState: itemModalStateType = {
  navigation: {
    levels: [],
  },
} as itemModalStateType;

export const itemModalSlice = createSlice({
  name: 'itemModal',
  initialState: itemInitialState as itemModalStateType,
  reducers: {
    setCurrentItem: (
      state: itemModalStateType,
      action: PayloadAction<ItemLevel>
    ) => {
      if (action.payload == undefined) {
        state.navigation.levels = [];
      } else {
        const foundNavigatedIndex = state.navigation.levels.findIndex(
          (x) => x.id == action.payload.id
        );
        const wasNavigatedTo = foundNavigatedIndex != -1;
        if (wasNavigatedTo) {
          const shrinkBreadcrumb = state.navigation.levels.splice(
            0,
            foundNavigatedIndex + 1
          );
          state.navigation.levels = shrinkBreadcrumb;
          state.navigation.levels[foundNavigatedIndex] = action.payload;
        } else {
          const expandBreadcrumb = [...state.navigation.levels, action.payload];
          state.navigation.levels = expandBreadcrumb;
        }
      }
    },
    updateItem: (
      state: itemModalStateType,
      action: PayloadAction<ItemLevel>
    ) => {
      const foundNavigatedIndex = state.navigation.levels.findIndex(
        (x) => x.id == action.payload.id
      );
      state.navigation.levels[foundNavigatedIndex] = action.payload;
    },
  },
});

export const { setCurrentItem, updateItem } = itemModalSlice.actions;

export default itemModalSlice.reducer;
